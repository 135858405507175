/* eslint-disable @typescript-eslint/no-floating-promises */
import { useState, useMemo } from 'react';
import { theme } from '@terminal/candidate/theme';
import { useRouter } from 'next/router';

import {
  ChakraProvider,
  Container,
  Box,
  Tabs,
  TabPanels,
  TabPanel,
  TabList,
  Tab,
  HStack,
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Heading,
  Center,
  Text,
} from '@terminal/design-system';
import { Hero } from './Hero';
import { MobileFilter } from './MobileFilter';
import { DropdownFilter } from './DropdownFilter';

import { JobMatches } from '@terminal/candidate/shared/modules/job/JobMatches';
import { JobDetail } from '@terminal/candidate/shared/modules/job/JobDetail';
import OpeningsModal from '@components/Modal/OpeningsModal';
import { NoJobMatches } from './NoJobMatches';
import { useDisclosure } from '@terminal/design-system';
import { serializeJobDetails } from '@terminal/candidate/features/job/utils';
import type { SelectAllJobsMarketingQuery } from '@_pages/engineering/job-openings/data';
import slugify from '@helpers/slugify';
import slugifySkills from '@helpers/slugifySkills';
import titleize from '@helpers/titleize';

import getConfig from 'next/config';
import { trackEvent } from '@lib/segment/trackAnalytics';
import {
  createJobOpeningListFullRoute_basedOnFilters,
  createSelectedJobRoute_basedOnFilters,
  supportedCountries,
} from '../utils';

const { publicRuntimeConfig } = getConfig();

export const JobMatchesWrapper = ({
  allJobsData,
  selectedJobID,
  currentFilters,
  marketingQueryparams,
  pageConfig,
  hasError = { 404: false },
}: {
  allJobsData: SelectAllJobsMarketingQuery;
  selectedJobID?: string;
  currentFilters: {
    role: string | null;
    company: string | null;
    country: string | null;
    skills: string | null;
  };
  marketingQueryparams: {
    [key: string]: string | string[];
  };
  pageConfig: { path: string };
  hasError?: {
    404: boolean;
  };
}) => {
  const router = useRouter();

  // The filter state is managed in JavaScript instead of using query parameters. This approach is used
  // because there are instances where a filter value exists in the nested query (received by this component
  // through the "currentFilters" prop) but it shouldn't be treated as a filter, in line with the intended user experience.
  const [filters, setFilters] = useState<{
    company: string;
    location: string;
    jobTitle: string;
    skills: string[];
  }>(() => {
    if (selectedJobID) {
      return {
        company: '',
        jobTitle: '',
        location: '',
        skills: [],
      };
    }

    return {
      company: currentFilters.company,
      jobTitle: currentFilters.role,
      location: currentFilters.country,
      skills: currentFilters.skills ? currentFilters.skills.split(',') : [],
    };
  });

  const { isOpen: isModalOpen, onClose: onModalClose } = useDisclosure();
  const { isOpen: isFilterOpen, onOpen: onFilterOpen, onClose: onFilterClose } = useDisclosure();

  const utmParams = Object.keys(marketingQueryparams)
    .map((key) => `${key}=${marketingQueryparams[key]}`)
    .join('&');

  const [filtersMobile, setFiltersMobile] = useState<{
    location: string;
    jobTitle: string;
    skills: string[];
  }>({ location: '', jobTitle: '', skills: [] });

  const convertLocationsToCountries = (locationList) => {
    // eslint-disable-next-line prefer-spread
    const allLocationList = [].concat.apply([], locationList);
    const countries = allLocationList.map((loc) => {
      const country = loc.includes(',') ? loc.split(',').slice(1).join(',').trim() : loc;
      if (country === 'Kitchener') {
        return 'Canada';
      }
      return country;
    });
    return countries;
  };

  const locationFilterOptions = () => {
    const locationOptions: {
      label: string;
      value: string;
    }[] = [];
    supportedCountries.map((item) => {
      locationOptions.push({
        label: item,
        value: slugify(item),
      });
    });

    // Add All to countries
    locationOptions.unshift({
      label: 'All',
      value: '',
    });

    return locationOptions;
  };

  const jobTitleFilterOptions = useMemo(() => {
    const jobTitleList = allJobsData.icims_job.flatMap((job) => job.family);

    const uniqueJobTitleList = jobTitleList.filter(function (item, pos, self) {
      return self.indexOf(item) === pos;
    });

    const jobTitleOptions: {
      label: string;
      value: string;
    }[] = [];
    // filter to remove null values
    uniqueJobTitleList
      ?.filter((item) => item)
      .map((item) => {
        jobTitleOptions?.push({
          label: item,
          value: slugify(item),
        });
      });

    // Add All to countries
    jobTitleOptions.unshift({
      label: 'All',
      value: '',
    });

    return jobTitleOptions;
  }, [allJobsData]);

  const skillsFilterOptions = useMemo(() => {
    const skillsOptions = [];
    allJobsData.skill.map((item) => {
      skillsOptions.push({
        label: item.name,
        value: slugifySkills(item.name),
      });
    });

    return skillsOptions.sort((a, b) =>
      a.label.toLowerCase() > b.label.toLowerCase()
        ? 1
        : b.label.toLowerCase() > a.label.toLowerCase()
        ? -1
        : 0,
    );
  }, [allJobsData]);

  const handleFilterSelectedItemChange = async (newfilter: string, value: string | string[]) => {
    window &&
      trackEvent(`${titleize(newfilter, '_')} Filter Applied`, {
        [newfilter]: value ? value : 'All',
      });

    const newFilters = {
      ...filters,
      ...{ [newfilter]: value },
    };

    const url = createJobOpeningListFullRoute_basedOnFilters(
      {
        company: newFilters.company || null,
        country: newFilters.location || null,
        role: newFilters.jobTitle || null,
        skills:
          (Array.isArray(newFilters.skills) ? newFilters.skills.join(',') : newFilters.skills) ||
          null,
      },
      marketingQueryparams,
    );

    await router.push(`${pageConfig.path}${url}`, undefined, {
      shallow: true,
    });

    setFilters((_currentFilters) => {
      return {
        ..._currentFilters,
        ...{ [newfilter]: value },
      };
    });
  };

  const handleSelectedItemsMobile = async (newFilters: {
    location: string;
    jobTitle: string;
    skills: string[];
  }) => {
    window &&
      trackEvent('All Filter Applied', {
        country: newFilters.location ? newFilters.location : 'All',
        job_title: newFilters.jobTitle ? newFilters.jobTitle : 'All',
        skills: newFilters.skills,
      });

    const newAndOldCombined_filters = {
      ...filters,
      ...newFilters,
    };

    const url = createJobOpeningListFullRoute_basedOnFilters(
      {
        company: newAndOldCombined_filters.company,
        country: newAndOldCombined_filters.location || null,
        role: newAndOldCombined_filters.jobTitle || null,
        skills:
          (Array.isArray(newAndOldCombined_filters.skills)
            ? newAndOldCombined_filters.skills.join(',')
            : newAndOldCombined_filters.skills) || null,
      },
      marketingQueryparams,
    );

    await router.push(`${pageConfig.path}${url}`, undefined, {
      shallow: true,
    });

    setFilters((_currentFilters) => {
      return {
        ..._currentFilters, // so that we can retain the company filter
        ...newFilters,
      };
    });
  };

  const calcMobileFilters = () => {
    let length = 0;
    if (filters.location && filters.location != 'all') {
      length = length + 1;
    }
    if (filters.jobTitle && filters.jobTitle != 'all') {
      length = length + 1;
    }
    if (filters.skills.length > 0) {
      length = length + filters.skills.length;
    }
    return length;
  };

  const onJobDetailClick = () => {
    if (sessionStorage.selectedJobClickCount) {
      sessionStorage.selectedJobClickCount = Number(sessionStorage.selectedJobClickCount) + 1;
    } else {
      sessionStorage.selectedJobClickCount = 1;
    }
  };

  const filteredJobList = useMemo(() => {
    let jobList = allJobsData.icims_job;
    if (filters.location) {
      jobList = jobList.filter((job) => {
        const locations = job.icims_job_locations.map((location) =>
          location.icims_location.value.toLowerCase(),
        );
        return convertLocationsToCountries(locations)
          .map((location) => slugify(location))
          .includes(filters.location);
      });
    }
    if (filters.jobTitle) {
      jobList = jobList.filter((job) => {
        return job.family && slugify(job.family) === filters.jobTitle;
      });
    }
    if (filters.skills.length > 0) {
      jobList = jobList.filter((job) => {
        const techStack = job.tech_stack?.split(',').map((stack) => slugifySkills(stack));

        if (!techStack || !techStack.length) return false;

        return techStack.some((stack) => filters.skills.includes(stack));
      });
    }
    return jobList;
  }, [filters, allJobsData]);

  const otherJobs: ReturnType<typeof serializeJobDetails>[] = filteredJobList.map((job) =>
    serializeJobDetails({
      job,
    }),
  );

  const otherJobsWithSeoTitle = otherJobs.map((job) => {
    return {
      ...job,
      jobDetail: {
        ...job.jobDetail,
        title: `${job.jobDetail.title}${
          job.skills.length ? ` - ${job.skills.slice(0, 3).join(', ')}` : ''
        }`,
      },
    };
  });

  const selectedJob =
    otherJobsWithSeoTitle.find((job) => `${job.id}` === selectedJobID) || otherJobsWithSeoTitle[0];

  const seoContent = `
  <p>At Terminal, we connect two communities divided by geography, but destined to transform tech: remote, career-minded, tech talent like YOU and fast-growing companies with global ambition.</p>
  <p>Terminal wants talented engineers to earn more, learn more and succeed as tech professionals while working from home. Named a “Best Place to Work” by Inc. magazine, Terminal offers developers exclusive, fully-remote roles at amazing companies, competitive salaries, and personalized experiences, and competitive salaries including equity. Our customers are looking for remote-ready rock stars in some of the hottest tech areas from blockchain and cloud engineering to machine learning, web development and more. As our needs expand as our customer base grows, we strongly encourage developers to apply now.</p>
  <p>Terminal hires a wide range of tech talent, including, but not limited to front-end developers, back-end developers, full stack developers, software engineers, mobile developers and more. Similarly, we recruit engineers across different tech stacks including .Net, Angular, AWS, C/C++, Django, Docker, Go, Java, Javascript, MySQL, Node, NoSQL, Python, React, Ruby, Typescript and others.</p>
  <p>Tired of your current role?  Join us as we fulfill our mission - unchanged since our founding in 2017 - to push the world of work forward by bringing career opportunities to premier tech talent globally.</p>`;

  const selectedItemTitle = (items, selectedItem) => {
    return (
      items.filter((item) => selectedItem.split('-').join(' ') === item.label.toLowerCase())[0]
        ?.label || ''
    );
  };

  const filterResultsCount = () => {
    let text = 'Showing All Jobs';
    if (filters.skills.length > 0) {
      text = 'Showing Relevant Jobs';
    }
    if (filters.location || filters.jobTitle) {
      const location = selectedItemTitle(locationFilterOptions(), filters.location || '');
      const jobTitle = selectedItemTitle(jobTitleFilterOptions, filters.jobTitle || '');
      text = `Filtering ${location} ${jobTitle} jobs`;
    }
    return (
      <Box p="0.75rem" mt={{ base: '3', md: '0' }} maxW={{ md: '25.625rem' }}>
        <Flex justifyContent="space-between" alignItems="center">
          <Box>{text}</Box>
          <Box fontWeight="bold" color="accent.main" fontSize="0.75rem">
            {filteredJobList?.length} results
          </Box>
        </Flex>
      </Box>
    );
  };

  const resetFilters = async () => {
    await router.push(pageConfig.path, undefined, {
      shallow: true,
    });

    setFilters({
      location: '',
      jobTitle: '',
      skills: [],
      company: '',
    });
  };

  return (
    <Box id="product" pb={{ base: '12', lg: '24' }} bgColor="white">
      <ChakraProvider theme={theme} cssVarsRoot="#product" resetCSS>
        <Box bg="bg.primary">
          {hasError[404] && (
            <Box bgColor="#F4F7F9">
              <Container>
                <Center py={12} flexDirection="column">
                  <Heading as="p" variant="heading-1" color="text.error">
                    Oops! Job Not Found
                  </Heading>
                  <Text mt={6} w="40rem">
                    We&rsquo;re sorry, but the job you&rsquo;re looking for is no longer actively
                    recruiting. Don&rsquo;t worry though, there are plenty more opportunities
                    available. perfect match.
                  </Text>
                </Center>
              </Container>
            </Box>
          )}
          {!selectedJobID && !hasError[404] && (
            <Box bgColor="#F4F7F9">
              <Container>
                <Hero />
              </Container>
            </Box>
          )}
          <Container id="filters">
            <Tabs pt="6">
              <TabList>
                <Tab>Jobs</Tab>
                <Tab>About</Tab>
              </TabList>
              <TabPanels>
                <TabPanel pt="6">
                  <Box display={{ md: 'none' }}>
                    <Button
                      variant="outline"
                      colorScheme="accent"
                      w="100%"
                      onClick={() => onFilterOpen()}
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M1.5 3.25201C1.08579 3.25201 0.75 3.5878 0.75 4.00201C0.75 4.41623 1.08579 4.75201 1.5 4.75201H14.5C14.9142 4.75201 15.25 4.41623 15.25 4.00201C15.25 3.5878 14.9142 3.25201 14.5 3.25201H1.5ZM2.75 8C2.75 7.58579 3.08579 7.25 3.5 7.25H12.5C12.9142 7.25 13.25 7.58579 13.25 8C13.25 8.41421 12.9142 8.75 12.5 8.75H3.5C3.08579 8.75 2.75 8.41421 2.75 8ZM4.75 12C4.75 11.5858 5.08579 11.25 5.5 11.25H10.5C10.9142 11.25 11.25 11.5858 11.25 12C11.25 12.4142 10.9142 12.75 10.5 12.75H5.5C5.08579 12.75 4.75 12.4142 4.75 12Z"
                          fill="#25383F"
                        />
                      </svg>
                      <Box ml="3">Filters</Box>
                      {calcMobileFilters() > 0 && (
                        <Flex
                          w="6"
                          h="6"
                          ml="3"
                          borderRadius="50%"
                          bgColor="accent.main"
                          color="white"
                          justifyContent="center"
                        >
                          {calcMobileFilters()}
                        </Flex>
                      )}
                    </Button>
                    <Modal isOpen={isFilterOpen} onClose={onFilterClose} size="xl" isCentered>
                      <ModalOverlay />
                      <ModalContent bgColor="grey.100" borderRadius="0">
                        <Box
                          position="fixed"
                          top="0"
                          left="0"
                          zIndex="1"
                          w="100%"
                          p="1.5rem 0.75rem"
                          bgColor="bg.secondary"
                        >
                          <Box fontWeight="bold">Filters</Box>
                          <ModalCloseButton size="lg" top="4" />
                        </Box>
                        <ModalBody>
                          <Box
                            position="fixed"
                            w="100%"
                            h="calc(100% - 10.5rem)"
                            left="0"
                            top="4.5rem"
                            bgColor="white"
                            overflow="scroll"
                          >
                            <Container>
                              <MobileFilter
                                title="Location"
                                selectedItem={filters.location}
                                items={locationFilterOptions()}
                                handleSelectedItem={(value) =>
                                  setFiltersMobile({
                                    ...filtersMobile,
                                    location: value as string,
                                  })
                                }
                              />
                              <MobileFilter
                                title="Job Title"
                                selectedItem={filters.jobTitle}
                                items={jobTitleFilterOptions}
                                handleSelectedItem={(value) =>
                                  setFiltersMobile({
                                    ...filtersMobile,
                                    jobTitle: value as string,
                                  })
                                }
                              />
                              <MobileFilter
                                title="Skills"
                                selectedItem={filters.skills}
                                items={skillsFilterOptions}
                                handleSelectedItem={(value) =>
                                  setFiltersMobile({
                                    ...filtersMobile,
                                    skills: value as string[],
                                  })
                                }
                                isMultiple={true}
                              />
                            </Container>
                          </Box>
                          <Box
                            position="fixed"
                            bottom="0"
                            left="0"
                            w="100%"
                            p="1.5rem 0.75rem"
                            bgColor="white"
                          >
                            <Button
                              w="100%"
                              onClick={() => {
                                handleSelectedItemsMobile(filtersMobile);
                                onFilterClose();
                              }}
                            >
                              Apply Filters
                            </Button>
                          </Box>
                        </ModalBody>
                      </ModalContent>
                    </Modal>
                  </Box>
                  <HStack spacing="0.625rem" display={{ base: 'none', md: 'flex' }}>
                    <DropdownFilter
                      title={filters.location ? 'Location:' : 'Select a location'}
                      selectedItem={filters.location || ''}
                      items={locationFilterOptions()}
                      handleSelectedItem={(value) =>
                        void handleFilterSelectedItemChange('location', value)
                      }
                    />
                    <DropdownFilter
                      title={filters.jobTitle ? 'Job Title:' : 'Select a job title'}
                      selectedItem={filters.jobTitle || ''}
                      items={jobTitleFilterOptions}
                      handleSelectedItem={(value) =>
                        void handleFilterSelectedItemChange('jobTitle', value)
                      }
                    />
                    <DropdownFilter
                      title={filters.skills.length > 0 ? 'Skills:' : 'Select a skill'}
                      selectedItem={filters.skills}
                      items={skillsFilterOptions}
                      handleSelectedItem={(value) =>
                        void handleFilterSelectedItemChange('skills', value)
                      }
                      isMultiple={true}
                    />
                    <Button
                      variant="ghost"
                      minW="7rem"
                      ml="1.125rem"
                      _focus={{ boxShadow: 'none' }}
                      onClick={() => void resetFilters()}
                    >
                      Reset Filter
                    </Button>
                  </HStack>
                  <JobMatches
                    shouldNotWrapWithContainer
                    onJobDetailClick={onJobDetailClick}
                    showIsSubscribedOn={false}
                    otherJobs={otherJobs || []}
                    activeSuggestion={selectedJobID ? parseInt(selectedJobID) : otherJobs?.[0]?.id}
                    createJobLinkHrefByID={(id: number): string => {
                      const job = otherJobs.find((job) => job.id === id);

                      return `${pageConfig.path}${createSelectedJobRoute_basedOnFilters(
                        job,
                        currentFilters,
                        marketingQueryparams,
                      )}`;
                    }}
                    jobSectionDetail={
                      selectedJob ? (
                        <JobDetail
                          isModalOpen={isModalOpen}
                          onModalClose={onModalClose}
                          isDrawerOpen={selectedJobID ? true : false}
                          onDrawerClose={() => {
                            const url = createJobOpeningListFullRoute_basedOnFilters(
                              {
                                company: filters.company || null,
                                country: filters.location || null,
                                role: filters.jobTitle || null,
                                skills:
                                  (Array.isArray(filters.skills)
                                    ? filters.skills.join(',')
                                    : filters.skills) || null,
                              },
                              marketingQueryparams,
                            );

                            void router.push(`${pageConfig.path}${url}`, undefined, {
                              shallow: true,
                            });
                            // void router.push(pageConfig.path);
                          }}
                          onApplyClick={() => {
                            // TODO: change this to href?
                            window &&
                              trackEvent('Cta Clicked', {
                                name: 'direct-apply',
                              });
                          }}
                          createJobApplyHref={(): string => {
                            return `${publicRuntimeConfig.applyUrl}/${selectedJob.id}/${utmParams}`;
                          }}
                          isApplyButtonLoading={false}
                          hasAlreadyApplied={false}
                          onCompleteProfileClick={() => {}}
                          // @ts-ignore
                          jobDetailData={selectedJob?.jobDetail || {}}
                          createCompanyHrefByID={(
                            organizationID: number,
                            orgName: string,
                          ): string => {
                            return `/company/${organizationID}/${slugify(orgName)}`;
                          }}
                        />
                      ) : (
                        <NoJobMatches resetFilters={() => void resetFilters()} />
                      )
                    }
                    jobsCount={filterResultsCount()}
                  />
                </TabPanel>
                <TabPanel pt="0">
                  <Box
                    mt="6"
                    dangerouslySetInnerHTML={{ __html: seoContent }}
                    sx={{
                      p: {
                        mb: '4',
                      },
                    }}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Container>
        </Box>
        <OpeningsModal utmParams={utmParams} />
      </ChakraProvider>
    </Box>
  );
};
